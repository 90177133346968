@import '~@timeedit/ui-components/lib/src/assets/styles/variables';
.scrollable-parent {
  display: flex;
  flex-flow: column;
  flex: 1;
  min-height: 0;
}

.activity-series-table {
  @extend .scrollable-parent;
  padding: 12px 8px 0;
  max-height: 100%;
  overflow: hidden;
  min-height: 0;
  padding: 0;
  margin: 0;

  tr.ant-table-row {
    cursor: pointer;
  }

  &--nested-table {
    .ant-table-row.ant-table-row-level-0 {
      background-color: $colorFillTertiary;
    }
  }

  .header {
    background-color: $colorFillTertiary;
    border-radius: 8px 8px 0 0;
  }

  .ant-table-footer {
    display: flex;
    align-items: center;

    .ant-pagination {
      margin-left: auto;
    }
  }

  .ant-table-wrapper {
    @extend .scrollable-parent;

    .ant-table-selection-column {
      position: relative;
      z-index: 2;
    }
    .ant-table-row-level-1 {
      .ant-table-cell.ant-table-cell-with-append {
        padding-left: 40px !important;
        & + .ant-table-cell {
          padding-left: 40px !important;
        }
      }
    }
    .ant-table-row-level-2 {
      .ant-table-cell.ant-table-cell-with-append {
        & + .ant-table-cell {
          padding-left: 40px !important;
        }
      }
    }

    .expand-icon {
      display: flex;
      align-items: center;
      height: 32px;
      margin-right: 8px;
      position: relative;
      z-index: 2;

      & + * {
        height: 32px;
        line-height: 32px;
      }
    }

    .ant-spin-nested-loading {
      max-height: 100%;
      display: flex;
      flex-flow: column;
    }

    .ant-spin-container,
    .ant-table,
    .ant-table-container,
    .ant-table-content {
      @extend .scrollable-parent;
    }

    .ant-table + .ant-pagination {
      display: none;
    }

    .ant-table-content {
      overflow: auto !important;
    }

    .ant-table-thead {
      top: 0;

      th {
        border-radius: 0 !important;
      }
    }
  }

  td,
  th {
    padding: 6px 8px !important;
  }

  .indent-level-1 {
    padding-left: 32px !important;
  }

  .indent-level-2 {
    padding-left: 64px !important;
  }
}

.expand-icon {
  float: left;
  cursor: pointer;
}
