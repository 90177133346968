@import './globals.scss';

.ant-btn.ant-btn-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.inner-content--wrapper {
  width: 100%;
  position: relative;
}

.small-heading-text,
input {
  font-size: 14px;
  color: $green10 !important;
}

.label-text {
  font-size: 14px;
  color: $green10 !important;
  margin: 0;
}

.ant-col {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  label {
    font-size: 14px;
  }
}

label {
  font-size: 14px !important;
}

.ant-transfer-list-content-item {
  padding: 0 0 0 1rem !important;
}

.ant-input,
.ant-select-selector {
  font-size: 14px;
  color: $green10 !important;
}

.form-item-text {
  font-size: 14px;
  color: $green10 !important;
  margin: 16px 0 0 0;
}

// Tooltip help text styling
.help-text-on-hover {
  position: relative;
  display: inline-block;
  &:hover {
    .help-text {
      visibility: visible;
    }
  }

  .help-text {
    visibility: hidden;
    width: 32em !important;
    font-size: 14px;
    background-color: $colorBgBase;
    color: $green10;
    text-align: left;
    border: 0.5px solid $magenta1 !important;
    padding: 4px !important;
    white-space: pre;
  }
}

.tooltip-maintenance {
  width: 1000px !important;
}

div.ant-transfer-operation {
  .ant-btn-primary {
    .anticon {
      color: $colorItemTextDisabled !important;
    }
  }
}

.ant-transfer-list {
  width: 23rem !important;
  height: 23rem !important;
}

.search-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;

  > span > span > span > input {
    font-size: 14px !important;
  }
}

.list-search--menu {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: 0 !important;

  .ant-dropdown-menu-item {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin: 0 !important;
  }
}

.list-search--menu > li > .ant-dropdown-menu-submenu-title {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.list-search--menu-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.nested-list-collapse > div > .ant-collapse-content-box {
  padding: 0 !important;
}

.nested-list-collapse > .ant-collapse-header {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  align-items: center;
  > span {
    padding: 0 !important;
  }
}

tr.drop-over-downward td {
  border-bottom: 2px dashed $blue7;
}

.ant-table-wrapper {
  width: 100% !important;
}

.ant-collapse-header {
  padding: 8px 0 12px 8px !important;
}

.ant-collapse-content-box {
  padding-left: 10 !important;
}

.ant-modal {
  max-height: calc(100% - 100px - 50px); // 100 from top, 50 from bottom
  display: flex;
  flex-flow: column;

  .ant-modal-content {
    display: flex;
    flex-flow: column;
    flex: 1;
    min-height: 0;

    .ant-modal-body {
      flex: 1;
      overflow: auto;
    }
  }
}

.text {
  &--success {
    color: $colorSuccess;
  }
  &--danger {
    color: $colorError;
  }
  &--disabled {
    color: $colorItemTextDisabled;
  }
  &--warning {
    color: $colorWarning;
  }
  &--info {
    color: $colorInfo;
  }
}

.text-center {
  text-align: center;
}

.ant-layout > .ant-layout {
  transform: translate(0, 0);
  position: relative;
}
